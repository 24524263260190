cs.ns('app.ui.trait.root.appservices')
app.ui.trait.root.appservices.ctrl = cs.trait({
    dynamics: {
        webSocketConnected: false,
        webSocketSupported: false
    },
    protos:   {

        setup () {
            this.base()

            this.subscribeForChildEvent('selectChannel', (ev, channelHash) => {
                const selectedChannel = _.find(this.model.value('global:data:channels'), (channel) => {
                    return channel.hash === channelHash
                })
                if (selectedChannel) {
                    this.model.value('global:data:selectedChannel', selectedChannel, true)
                    this.model.value('global:data:currentContent', null)
                } else {
                    this.model.value('global:data:selectedChannel', null)
                    console.error('Error while selecting channel with hash', channelHash, '- Channel was not found')
                }
            })

            this.subscribeForChildEvent('selectContent', (ev, contentHash) => {
                const selectedContent = _.find(this.model.value('global:data:contents'), (content) => {
                    return content.hash === contentHash
                })
                if (selectedContent) {
                    this.model.value('global:data:currentContent', selectedContent, true)
                } else {
                    this.model.value('global:data:currentContent', null)
                    console.error('Error while selecting content with hash', contentHash, '- Content was not found')
                }
            })

            this.subscribeForChildEvent('selectPreviousContent', () => {
                const selectedContent = this.model.value('global:data:currentContent')
                const contentList     = this.model.value('global:data:contents')
                const currentIdx      = _.findIndex(contentList, { hash: selectedContent.hash })
                let previousIdx       = currentIdx - 1
                if (previousIdx < 0)
                    previousIdx = contentList.length - 1
                this.publishEventToParent('selectContent', contentList[previousIdx].hash)
            })

            this.subscribeForChildEvent('selectNextContent', () => {
                const selectedContent = this.model.value('global:data:currentContent')
                const contentList     = this.model.value('global:data:contents')
                const currentIdx      = _.findIndex(contentList, { hash: selectedContent.hash })
                let nextIdx           = currentIdx + 1
                if (nextIdx >= contentList.length)
                    nextIdx = 0
                this.publishEventToParent('selectContent', contentList[nextIdx].hash)
            })

            this.subscribeForChildEvent('pauseContent', () => {
                // pause only if websocket is established or if websockets are not supported at all
                if (this.webSocketConnected || !this.webSocketSupported)
                    this.model.value('global:state:paused', true)
            })

            this.subscribeForChildEvent('resumeContent', () => {
                // resume only if websocket is established or if websockets are not supported at all
                if (this.webSocketConnected || !this.webSocketSupported)
                    this.model.value('global:state:paused', false)
            })

            this.subscribeForChildEvent('requestFullscreen', () => {
                if (this.model.value('global:state:fullscreenable') && document && document.body) {
                    if (document.body.requestFullscreen) {
                        document.body.requestFullscreen();
                    } else if (document.body.webkitRequestFullscreen) {
                        document.body.webkitRequestFullscreen();
                    } else if (document.body.mozRequestFullScreen) {
                        document.body.mozRequestFullScreen();
                    } else if (document.body.msRequestFullscreen) {
                        document.body.msRequestFullscreen();
                    }
                }
            })

            this.subscribeForChildEvent('exitFullscreen', () => {
                if (this.model.value('global:state:fullscreenable') && document) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }
            })

            this.subscribeForChildEvent('activateLogin', () => {
                this.openModalDialog({
                    title:    i18next.t("appTraitAppServices.title.login"),
                    dialog:   app.ui.composite.login.ctrl,
                    acceptCB: () => {
                        this.model.value('global:state:anonymous', false)
                        this.closeModalDialog()
                    },
                    cancelCB: () => { this.closeModalDialog() }
                })
            })

            this.subscribeForChildEvent('anonymousLogin', () => {
                this.publishEventToParent('login', undefined, undefined, undefined, () => {
                    this.model.value('global:state:anonymous', true)
                }, () => {
                    console.error('Error while login in')
                })
            })

            this.subscribeForChildEvent('activateDirectEntry', () => {
                this.openModalDialog({
                    title:    i18next.t("appTraitAppServices.title.directEntry"),
                    dialog:   app.ui.composite.directEntry.ctrl,
                    acceptCB: (token) => {
                        if (window && window.location) {
                            const hashMananger = app.HashManager.parseURL(window.location.hash)
                            hashMananger.hash(this.directEntry.hashtag, token)
                            window.location.hash = hashMananger.toString()
                        }
                        this.closeModalDialog()
                    },
                    cancelCB: () => { this.closeModalDialog() }
                })
            })

            this.subscribeForChildEvent('webSocketConnected', () => {
                // reset the pause state to the state before the connection was lost
                this.model.value('global:state:paused', this.model.value('state:previousPaused'))
                this.webSocketConnected = true;
                // if a websocket connection got established at least once we can assume that they are supported by the environment
                this.webSocketSupported = true;
            })

            this.subscribeForChildEvent('webSocketDisconnected', () => {
                // no websocket connection means pause the content but remember the former value
                const paused = this.model.value('global:state:paused', true)
                this.model.value('state:previousPaused', paused)
                this.webSocketConnected = false;
            })

            this.observeOwnModel('global:data:selectedChannel', (ev, channel) => {
                if (channel && channel.tag) {
                    this.publishEventToParent('readContentList', channel.tag, (contentList) => {
                        let orderedcontentList = contentList
                        if (channel.shuffle) {
                            let groupedContent = {}
                            const nullGroupId = "__null__"
                            // group all content by their group selector or '__null__'
                            _.forEach(contentList, (eachContent) => {
                                const groupId = eachContent.group || nullGroupId
                                if (!_.isArray(groupedContent[groupId]))
                                    groupedContent[groupId] = []
                                groupedContent[groupId].push(eachContent)
                            })
                            let contentListWithGroups = []
                            // wrap all groups except '__null__' in an array to be able to flatten the list once
                            // to receive all not grouped contents along side with groups
                            _.forOwn(groupedContent, (value, key) => {
                                contentListWithGroups.push(key === nullGroupId ? value : [value])
                            })
                            contentListWithGroups = _.flatten(contentListWithGroups)
                            // sort the stuff randomly but keep groups together
                            contentListWithGroups.sort((/*a, b*/) => {return 0.5 - Math.random()})
                            // ungroup
                            orderedcontentList = _.flatten(contentListWithGroups)
                        }
                        let currentContent = this.model.value('global:data:currentContent')
                        if (!currentContent && orderedcontentList.length)
                            currentContent = orderedcontentList[channel.randomStart ? Math.floor(Math.random() * orderedcontentList.length) : 0]
                        this.model.value('global:data:contents', orderedcontentList)
                        const autoSelectContentTag = this.model.value('data:autoSelectContentHash', '')
                        let autoSelectContent
                        if (autoSelectContentTag !== '') {
                            autoSelectContent = _.find(orderedcontentList, (content) => { return content && content.hash === autoSelectContentTag})
                            if (autoSelectContent)
                                this.publishEventToParent('selectContent', autoSelectContent.hash)
                        } else {
                            this.model.value('global:data:currentContent', currentContent, true)
                        }
                        this.publishEventToParent('directEntry', channel, currentContent)
                    }, this.onError.bind(this))
                } else {
                    this.model.value('global:data:contents', [], true)
                    this.publishEventToParent('directEntry', undefined, undefined)
                }
            }, { op: 'changed' })

            this.observeOwnModel('global:data:currentContent', (ev, content) => {
                this.publishEventToParent('directEntry', this.model.value('global:data:selectedChannel'), content)
                this.model.value('global:data:nextContent', content && content.hash ? this.getNextContent(content.hash) : null, true)
            }, { op: 'changed' })

            this.observeOwnModel('global:data:contents', (ev, contentList) => {
                if (contentList.length === 0) {
                    this.model.value('global:data:currentContent', null, true)
                }
            }, { op: 'changed' })

            this.observeOwnModel('global:state:anonymous', (/*ev, state*/) => {
                this.publishEventToParent('readChannels', (channels) => {
                    if (channels) {
                        this.model.value('global:data:channels', channels)
                    }
                }, () => {
                    console.error('Error while reading channel list')
                })
            }, { op: 'changed' })

            this.observeOwnModel('global:state:fullscreenable', (ev, state) => {
                if (state) {
                    // detect enter or exit fullscreen mode
                    document.addEventListener('webkitfullscreenchange', this.fullscreenChange.bind(this));
                    document.addEventListener('mozfullscreenchange', this.fullscreenChange.bind(this));
                    document.addEventListener('fullscreenchange', this.fullscreenChange.bind(this));
                    document.addEventListener('MSFullscreenChange', this.fullscreenChange.bind(this));
                } else {
                    document.removeEventListener('webkitfullscreenchange', this.fullscreenChange.bind(this));
                    document.removeEventListener('mozfullscreenchange', this.fullscreenChange.bind(this));
                    document.removeEventListener('fullscreenchange', this.fullscreenChange.bind(this));
                    document.removeEventListener('MSFullscreenChange', this.fullscreenChange.bind(this));
                }
            }, { op: 'changed' })

            /*  Check for embedded mode via iframe  */
            if (window && window.self && window.top) {
                this.model.value('global:state:embedded', window.self !== window.top)
            } else {
                this.model.value('global:state:embedded', false)
            }

            /**  Check Fullscreen API  */
            this.model.value('global:state:fullscreenable',
                !!(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled))

            if (this.model.value('global:state:embedded')) {
                this.model.value('state:previousPaused', true)
            }
        },

        prepare () {
            this.base()

            cs(this).guard('render', +1)
            this.publishEventToParent('access', (access) => {
                this.model.value('global:state:anonymous', access.authClass === 'none' && access.tokenClass === 'none')
                cs(this).guard('render', -1)
            }, () => {
                this.publishEventToParent('login', undefined, undefined, undefined, () => {
                    cs(this).guard('render', -1)
                    this.model.value('global:state:anonymous', true)
                }, () => {
                    console.error('Error while login in')
                    cs(this).guard('render', -1)
                })
            })

        },

        render () {
            this.base()
            cs(this).guard('show', +1)
            this.publishEventToParent('readChannels', (channels) => {
                if (channels) {
                    this.model.value('global:data:channels', channels)
                    cs(this).guard('show', -1)
                }
            }, () => {
                console.error('Error while reading channel list')
                cs(this).guard('show', -1)
            })
        },

        initialize (options) {
            this.base(options)
            if (options.hash) {
                this.publishEventToParent('readUrlMap', options.hash, (result) => {
                    this.initializeContent({
                        autoplay: options.autoplay,
                        channel:  result.chn,
                        content:  result.cnt,
                        hash:     options.hash
                    })
                }, this.onError.bind(this))
            }
        },

        initializeContent (options) {
            this.base(options)
            const selectedChannel = this.model.value('global:data:selectedChannel')
            const channelTag      = options.channel
            let channelChanged    = true
            if (selectedChannel && selectedChannel.tag === channelTag)
                channelChanged = false
            const channel = _.find(this.model.value('global:data:channels'), (each) => {
                return each.tag === channelTag
            })
            if (!channel) {
                this.model.value('global:data:selectedChannel', null, true)
            } else if (channelChanged) {
                this.publishEventToParent('selectChannel', channel.hash)
            }
            if (options.content) {
                const contents = this.model.value('global:data:contents')
                let contentIdx = _.findIndex(contents, (content) => { return content && content.hash === options.hash})
                if (isNaN(contentIdx))
                    contentIdx = 0
                if (!selectedChannel || channelChanged) {
                    this.model.value('data:autoSelectContentHash', options.hash)
                } else {
                    if (contentIdx <= -1 || contentIdx >= contents.length)
                        contentIdx = 0
                    this.publishEventToParent('selectContent', contents[contentIdx].hash)
                }
            } else if (!channelChanged) {
                this.model.touch('global:data:selectedChannel')
            }
        },

        getNextContent (contentHash) {
            const contents = this.model.value('global:data:contents')
            let result     = null
            if (contents.length) {
                const currentIdx = _.findIndex(contents, (content) => {
                    return content.hash === contentHash
                })
                let idx          = currentIdx + 1
                if (idx >= contents.length)
                    idx = 0
                result = contents[idx]
            }
            return result
        },

        onWebSocketMessage (message) {
            this.base(message)
            let data = JSON.parse(message.data)
            if (data && data.cmd === 'INDEX-UPDATED')
                this.handleIndexUpdate()
        },

        handleIndexUpdate () {
            this.base()

            this.publishEventToParent('readChannels', (channels) => {
                const selectedChannel = this.model.value('global:data:selectedChannel')
                if (channels)
                    this.model.value('global:data:channels', channels)
                if (selectedChannel && _.find(channels, (channel) => { return channel.hash === selectedChannel.hash }))
                    this.model.touch('global:data:selectedChannel')
            }, () => {
                console.error('Error while reading channel list')
            })
        },

        fullscreenChange () {
            this.model.value('global:state:fullscreen',
                !!(document.fullscreenEnabled || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement))
        }
    }
})
